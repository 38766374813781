import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'

const ButtonWhite = ({ btnLabel, btnClick }) => {
  const isScreensaver = window.location.pathname === '/';
  return (
    <>
      <button
        className='bg-white text-[#262626] text-[14px] sm:text-lg md:text-2xl uppercase font-bold flex px-[40px] py-[16px] rounded-[11px] items-center justify-center hover:bg-gray-200 transition-all btnWhite'
        onClick={btnClick}
      >
        {!isScreensaver  && (
          <FontAwesomeIcon icon={faChevronCircleLeft} className='mr-2' />
        )}
        {btnLabel}
      </button>
    </>
  )
}

export default ButtonWhite
