import React from 'react'
import { useTranslation } from 'react-i18next'

const ApiCallingToaster = () => {
  const { t } = useTranslation()
  return (
    <>
        <div className='z-1 overlay fixed w-full h-full left-0 top-0'></div>
        <div className='absolute left-[50%] top-[50%] bg-[white] text-[#262626] font-bold flex items-center justify-center w-[90%] sm:w-[30%] md:w-[80%] lg:w-[50%] m-auto text-[20px] p-[20px] sm:p-[50px] rounded-[15px] prompt text-center z-2'>
          {t('headings.api_Error')}
        </div>
    </>
  )
}

export default ApiCallingToaster
