// src/utils/favicon.js

export const updateFavicon = (url) => {
  const link = document.querySelector("link[rel~='icon']");
  if (link) {
    link.href = url;
  } else {
    const newLink = document.createElement("link");
    newLink.rel = "icon";
    newLink.href = url;
    document.getElementsByTagName("head")[0].appendChild(newLink);
  }
};