import React from 'react';
import ButtonWhite from '../Button/ButtonWhite';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons'

const NotFoundPage = () => {

  const logo = sessionStorage.getItem('settingLogo')
  const home_Bg1 = sessionStorage.getItem('home_Bg1')
  const home_Bg2 = sessionStorage.getItem('home_Bg2')

  const currentUrl = window.location.href;
  console.log(currentUrl)

  const navigate = useNavigate();
  const handleBtnClick = () => {
    navigate('/')
  }

  return (
    <div className='firstScreen errorPage' style={{backgroundImage : `linear-gradient(180deg, ${home_Bg1} 0%, ${home_Bg2} 100%)`}}>
      <div className='flex h-dvh items-center flex-col justify-center max-w-full px-[40px] lg:max-w-[1280px] m-auto'>
        <FontAwesomeIcon icon={faFaceFrown} className='lg:text-[154px] sm:text-[100px] text-[70px] mb-[20px] sm:mb-0 text-white' />
        <h1 className='lg:text-[8em] sm:text-[6em] text-[3em] text-white font-bold mb-0 tracking-[11px]'>404</h1>
        <div className='text-center mb-[30px]'>
          <h2 className='sm:text-[36px] text-[22px] text-white mb-[20px]'>Page Not Found</h2>
          <p className='sm:text-[20px] text-[16px] text-gray-100 mb-[10px] break-words'>The requested URL <span className='text-white font-extrabold'>{currentUrl}</span> was not found on this server.</p>
          <p className=' text-white sm:text-[18px] text-[14px]'>That’s all we know.</p>
        </div>
        <ButtonWhite btnLabel="Back to home" btnClick={handleBtnClick} />
      </div>
    </div>
  );
};

export default NotFoundPage;
