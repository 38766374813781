import React from 'react'

const DynamicApiCallingToaster = (props) => {
  return (
    <>
        <div className='z-1 overlay absolute w-full h-screen left-0 top-0'></div>
        <div className='absolute left-[50%] top-[50%] bg-[white] text-[#262626] flex-col font-bold flex items-center justify-center text-[20px] py-[40px] px-[20px] sm:p-[50px] rounded-[15px] prompt text-center z-2'>
          {props.content}
        </div>  
    </>
  )
}

export default DynamicApiCallingToaster
