import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
// import logo from '../images/logoLight.png'

const LogoLight = () => {
  const logoSrc = sessionStorage.getItem('settingLogo'); 

  const location = useLocation();
  const [isLogoAlign, setIsLogoAlign] = useState(false)
  useEffect(() => {
    if(location.pathname == '/') {
        setIsLogoAlign(true)
    }
  })
  return (
    <div className={isLogoAlign ? "flex justify-start items-center h-full logoAlign" : "flex justify-center items-center h-full"}>
      <div className="sm:w-[120px] w-[80px] h-full logo">
          <div style={{backgroundImage: `url(${logoSrc})`}} className='h-full bg-no-repeat bg-center bg-[length:80px] sm:bg-[length:120px] companyLogo'></div>
      </div> 
    </div>
    
  )
}

export default LogoLight
