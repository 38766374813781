import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../lang/en.json';
import translationTA from '../lang/ta.json';
import translationMS from '../lang/ms.json';
import translationTH from '../lang/th.json';
import translationJA from '../lang/ja.json';
import translationKO from '../lang/ko.json';
import translationSI from '../lang/si.json';
import translationMY from '../lang/my.json';
import translationzhHankHK from '../lang/zh-HANK-HK.json'
import translationZH from '../lang/zh.json'
import translationID from '../lang/id.json'
import translationTL from '../lang/tl.json'
import translationFR from '../lang/fr.json'
import translationBG from "../lang/bg.json"
import translationVI from "../lang/vi.json"
import translationKM from "../lang/km.json"
import translationBN from "../lang/bn.json"


const getLanguageFromSessionStorage = () => {
  const savedLanguage = sessionStorage.getItem('selectedLanguage'); 
  return savedLanguage || 'en';

};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      ms : {
        translation : translationMS,
      },
      ta : {
        translation : translationTA
      },
      th : {
        translation: translationTH,
      },
      ja: {
        translation: translationJA,
      },
      ko: {
        translation: translationKO,
      },
      si : {
        translation : translationSI
      },
      my : {
        translation: translationMY,
      },
      zh : {
        translation: translationZH,
      },
      zhHankHK : {
        translation: translationzhHankHK,
      },
      id : {
        translation: translationID,
      },
      tl : {
        translation : translationTL
      },
      fr : {
        translation : translationFR
      },
      bg : {
        translation : translationBG
      },
      vi : {
        translation : translationVI
      }, 
      km : {
        translation : translationKM
      }, 
      bn : {
        translation : translationBN
      }
    },
    lng: getLanguageFromSessionStorage(), // Set language from local storage
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
