import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import loader from '../images/loader.gif';
import ApiCallingToaster from '../Components/Toaster/ApiCallingToaster';
import DynamicApiCallingToaster from '../Components/Toaster/DynamicApiCallingToaster';
import { getHeaders } from '../utils/apiUtils'; 
import KioskDevice from '../Components/KioskDevice';
import ReCaptcha from './ReCaptcha'
import { updateFavicon } from '../utils/favicon';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Header from '../Components/Headers/Header';


var company_name
var primaryColor
var secondaryColor
var headerColor 

var firstStar;
var secondStar;
var thirdStar;
var fourthStar;
var fifthStar;

var home_Bg1
var home_Bg2

var buttonTextColor
var enable_back_camera

const Screensaver = () => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [bgImg, setBgImg] = useState(sessionStorage.getItem('splashImg') || null);
  const [logo, setLogo] = useState(sessionStorage.getItem('settingLogo') || null);
  const [loading, setLoading] = useState(true);
  const [defaultApiError, setDefaultApiError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState(false);
  const [deviceType, setDeviceType] = useState('mobile');
  const deviceTypeRef = useRef('');
  const [kioskDevice, setKioskDevice] = useState()
  const [favicon, setFavicon] = useState(null);
  const [fontFamily, setFontFamily] = useState(''); 
  const [showHeader, setShowHeader] = useState(false);
  const currentUrl = window.location.href;
  sessionStorage.setItem('currentUrl', currentUrl);
  const [isOffline, setIsOffline] = useState(!navigator.onLine); 

  useEffect(() => {
    const uniqueKey = () => {
      var uuid = uuidv4();
      if (!sessionStorage.getItem('uuid')) {
        sessionStorage.setItem('uuid', uuid);
      }
    }
    setTimeout(() => {
      uniqueKey();
    }, 500)
    const savedUUID = sessionStorage.getItem('uuid');
      if (!savedUUID) {
        const queryParams = new URLSearchParams(window.location.search);
        const osType = queryParams.get('os');
          if (!savedUUID && !osType) {
              history('/');
          }
      }
  }, [history]);
  const handleResize = async () => {
    const width = window.innerWidth;
    if (width <= 767) {
      setDeviceType('mobile');
      deviceTypeRef.current = 'mobile';
    } else if (width >= 768 && width <= 1024) {
      setDeviceType('tab');
      deviceTypeRef.current = 'tab';
    } else {
      setDeviceType('web');
      deviceTypeRef.current = 'web';
    }
    sessionStorage.setItem('deviceSize', deviceTypeRef.current);
  };
  const loadFont = (fontFamily) => {
    const fontFaces = {
      'NotoSans-Medium': 'NotoSans-Medium',  
      'Lato-Regular': 'Lato-Regular',
      'Raleway-Medium': 'Raleway-Medium',
      'Poppins-Medium': 'Poppins-Medium',
      'Inter-Medium': 'Inter-Medium',
      'OpenSans-Medium': 'OpenSans-Medium',
      'Times New Roman': 'Times New Roma',
      'Merriweather-Regular': 'Merriweather-Regular',
      'Oswald-Medium': 'Oswald-Medium',
      'Rubik-Medium': 'Rubik-Medium'
  };

    if (fontFaces[fontFamily]) {
      const fontFace = new FontFace(fontFaces[fontFamily], `url(${currentUrl}src/fonts/${fontFaces[fontFamily]}.woff2)`);
        console.log('fontFace', fontFace)
        fontFace.load().then((loadedFace) => {
            document.fonts.add(loadedFace);
            document.body.style.fontFamily = fontFaces[fontFamily];
        }).catch((error) => {
            console.error(`Failed to load font: ${fontFamily}`, error);
        });
    }
};
useEffect(() => {
  const handleOffline = () => {
  setIsOffline(true);
  // Prevent navigation to another page
  history(window.location.pathname);
  };
  const handleOnline = () => {
    setIsOffline(false);
  };
  
  window.addEventListener('offline', handleOffline);
  window.addEventListener('online', handleOnline);
  
  return () => {
    window.removeEventListener('offline', handleOffline);
    window.removeEventListener('online', handleOnline);
  };
}, [history])
  const fetchGeneralSetting = async () => {
    try {
      setDefaultApiError(false);
      const settingUrl = `${process.env.REACT_APP_BASE_URL_API}general-setting`;
      const settingResponse = await fetch(settingUrl, {
        method: 'GET',
        headers: getHeaders(),
      });

      if (!settingResponse.ok) {
        const errorData = await settingResponse.json();
        let errorMessage = errorData.error.message;
        setApiError(true);
        setApiErrorMsg(errorMessage);
        return;
      }

      const settingData = await settingResponse.json();
      const settingImg = settingData.data.company.queue_waiting_image;
      const settingLogo = settingData.data.company.logo;
      company_name = settingData.data.company.company_name;

      const noAgent = settingData.data.company.no_agent;
      const mmUrl = settingData.data.company.maintenanceModeImage;
      const mmStatus = settingData.data.company.maintenance_mode;
      const splashImg = settingData.data.company.kiosk_device.screensaver;

      const kioskId = settingData.data.company.kiosk_device.kiosk_id;
      const showPopupTime = settingData.data.inactivity_popup_show_time;
      const dismissPopupTime = settingData.data.inactivity_popup_dismiss_time;
      const privacyPage = settingData.data.company.privacy_page

      const privacy_updated_at = settingData.data.company.privacy_updated_at
      const privacy_enabled = settingData.data.company.privacy_enabled
      const thankYouImg = settingData.data.company.thankyouImage
      
      firstStar = settingData.data.company.one_star_rating;       

      secondStar  = settingData.data.company.two_star_rating;
      thirdStar  = settingData.data.company.three_star_rating;
      fourthStar  = settingData.data.company.four_star_rating;
      fifthStar  = settingData.data.company.five_star_rating;

      const fetchedFontFamily = settingData.data.company.theme_customization.font;
      primaryColor = settingData.data.company.theme_customization.primary_color;
      secondaryColor = settingData.data.company.theme_customization.secondary_color;
      headerColor  = settingData.data.company.theme_customization.header_color; 
      home_Bg1 = settingData.data.company.theme_customization.home_background_color_1;
      home_Bg2 = settingData.data.company.theme_customization.home_background_color_2;
      buttonTextColor = settingData.data.company.theme_customization.button_text
      enable_back_camera = settingData.data.company.enable_back_camera

      const textColorVal = settingData.data.company.is_voncierge

      updateFavicon(settingData.data.company.favicon);
      if(kioskId == null || kioskId == '') {
          setKioskDevice(true);
      }
      else {
        setKioskDevice(false);
      }
      sessionStorage.setItem('settingImg', settingImg);
      sessionStorage.setItem('settingLogo', settingLogo);
      sessionStorage.setItem('inactivity_popup_show_time', showPopupTime);
      sessionStorage.setItem('inactivity_popup_dismiss_time', dismissPopupTime);
      
      sessionStorage.setItem('company_name', company_name);
      sessionStorage.setItem('no_agent', noAgent);
      sessionStorage.setItem('mmUrl', mmUrl);
      sessionStorage.setItem('mmStatus', mmStatus);

      sessionStorage.setItem('splashImg', splashImg);
      sessionStorage.setItem('kioskId', kioskId);
      sessionStorage.setItem('privacyPage', privacyPage)
      sessionStorage.setItem('privacyDate', privacy_updated_at)

      sessionStorage.setItem('privacyStatus', privacy_enabled)
      sessionStorage.setItem('thankYouImg', thankYouImg)
      sessionStorage.setItem('fetchedFontFamily', fetchedFontFamily)
      sessionStorage.setItem('headerColor', headerColor)

      sessionStorage.setItem('textColorVal', textColorVal)
      sessionStorage.setItem('firstStar', firstStar)
      sessionStorage.setItem('secondStar', secondStar)
      sessionStorage.setItem('thirdStar', thirdStar)

      sessionStorage.setItem('fourthStar', fourthStar)
      sessionStorage.setItem('fifthStar', fifthStar)
      sessionStorage.setItem('home_Bg1', home_Bg1)
      sessionStorage.setItem('home_Bg2', home_Bg2)
      sessionStorage.setItem('primaryColor', primaryColor)
      sessionStorage.setItem('secondaryColor', secondaryColor)
      sessionStorage.setItem('buttonTextColor', buttonTextColor)
      sessionStorage.setItem('enable_back_camera', enable_back_camera)


      setBgImg(splashImg); 
      setFontFamily(fetchedFontFamily);
      loadFont(fetchedFontFamily);

      document.body.style.fontFamily = fetchedFontFamily;
      if (mmStatus == 1) {
        history('/maintenance'); 
      }
      setLogo(settingLogo);
      var companyClass = sessionStorage.getItem('textColorVal');
      if (companyClass == 0) {
        document.body.classList.add('changi');
        document.body.classList.remove('voncierge');
        setShowHeader(false);
      } else {
        document.body.classList.add('voncierge');
        document.body.classList.remove('changi');
        setShowHeader(true);
      }
    } catch (error) {
      setDefaultApiError(true);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (company_name) {
      const cleanedCompanyName = company_name.replace(/\s+/g, '');
      document.body.classList.add(cleanedCompanyName);
    }
  }, [company_name])


  useEffect(() => {
    handleResize();      
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchGeneralSetting();
  }, [])
 
  useEffect(() => {
    const keysToPreserve = ['settingImg'];
    for (let key in sessionStorage) {
      if (!keysToPreserve.includes(key)) {
        sessionStorage.removeItem(key);
      }
    }
  }, [history]);
  useEffect(() => {
    if (favicon) {
      const link = document.createElement('link');
      link.rel = 'icon';
      link.href = favicon;
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, [favicon]);

  useEffect(() => {
    function handleVisibilityChange() {
      if (isMobileOrTablet()) {
        if(document.visibilityState === 'visible') {
          window.location.reload();
        }
      }
    }
    function isMobileOrTablet() {
      const userAgent = navigator.userAgent.toLowerCase();
      return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  const isChangiClassPresent = document.body.classList.contains('changi');
  return (
    <>
      <Helmet>
          <title>{company_name ? `${company_name} | Home` : 'Voncierge | Home'}</title>
      </Helmet> 
      {deviceType === 'mobile' || deviceType === 'tab' ? ( 
        <div className='firstScreen h-dvh relative' style={{backgroundImage : `linear-gradient(180deg, ${home_Bg1} 0%, ${home_Bg2} 100%)`}}>
          {showHeader && <Header />}
          <div  className='bg-bottom bg-contain bg-no-repeat w-[80vw] m-auto screenHeight'>
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center w-full h-dvh bg-black bg-opacity-75">
                <img src={loader} className='w-[60px]' alt='Loader' />
              </div>
            )}
            {!loading && bgImg && logo && (
              <div className='flex justify-center relative h-full'>
                <div className="my-[30px] flex flex-col justify-between">
                  <div>
                    {isChangiClassPresent && <div style={{backgroundImage : `url(${logo})`}} className='w-[94px] m-auto h-[54px] bg-contain bg-no-repeat logo'></div> }
                    <div className="text-center">
                      <h1 className='text-white text-[22px] font-bold mt-[20px]'>{company_name}</h1>
                      <h6 className='text-white text-[16px] mb-[30px]'>Need help? Speak to our Ambassador.</h6>
                    </div>
                  </div>
                  <div className='text-center'>
                    <div className='text-center rounded-[30px] mt-[30px] m-auto captchaDiv mb-[20px]'>
                        <h2 className='text-white text-[20px] font-bold'>ReCAPTCHA Verification</h2>
                        <h6 className='text-white text-[16px]'>We need to know you’re not a robot.</h6>
                        <div className='text-center m-auto mt-[30px]'>
                            <ReCaptcha />
                        </div>
                    </div>
                  </div>
                  <div className='bg-bottom bgImg bg-contain bg-no-repeat h-[240px] sm:h-[300px] md:h-[370px] w-full' style={{ backgroundImage: `url(${bgImg})`}}></div>
                </div>
              </div>
            )}
            
            <div className='absolute bottom-4 right-4 p-2 bg-gray-700 text-white rounded hidden'>
              Device Size: {deviceType}
            </div>
            {defaultApiError && <ApiCallingToaster />}
            {apiError && <DynamicApiCallingToaster content={apiErrorMsg} />}
            {kioskDevice && <KioskDevice /> }
            <div className={isOffline ? "block fixed bg-themeColor text-white text-center py-2 bottom-0 w-full left-0" : "hidden"}>
              {t('headings.no_internet_popup_label')}
            </div>
          </div>
        </div>
        ) : (
        <div className='firstScreen xl:h-screen xl:min-h-screen h-screen relative' style={{backgroundImage : `linear-gradient(180deg, ${home_Bg1} 0%, ${home_Bg2} 100%)`}}>
          {showHeader && <Header />}
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
              <img src={loader} className='w-[60px]' alt='Loader' />
            </div>
          )}
        <div className='screenWidth screenHeight flex h-full items-center max-w-[1280px] m-auto'>
          
          {!loading && bgImg && logo && (
            <div className='flex justify-center relative h-full fullPage items-center'>
              <div>
                {isChangiClassPresent &&  <div style={{backgroundImage : `url(${logo})`}} className='w-[150px] h-[70px] bg-contain bg-no-repeat logo'></div> }
                <div>
                  <h1 className='text-white text-[48px] font-bold mt-[20px] leading-[57px] w-[574px]'>{company_name}</h1>
                  <h6 className='text-white text-[20px] mt-[15px] w-[444px]'>Complete the reCAPTCHA verification so that we know you’re not a robot</h6>
                  <div className='rounded-[30px] captchaDiv sm:mt-[32px]'>
                      <div className={isOffline ? "mt-[30px] pointer-events-none" : "mt-[30px]"}>
                        <ReCaptcha />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div style={{ backgroundImage: `url(${bgImg})`}} className='bg-bottom bg-contain bg-no-repeat h-[450px] w-[360px] m-auto mr-0'>

          </div>
          <div className='absolute bottom-4 right-4 p-2 bg-gray-700 text-white rounded hidden'>
            Device Size: {deviceType} 
          </div>
          {defaultApiError && <ApiCallingToaster />}
          {apiError && <DynamicApiCallingToaster content={apiErrorMsg} />}
          {kioskDevice && <KioskDevice /> }
          <div className={isOffline ? "block fixed bg-themeColor text-white text-center py-2 bottom-0 w-full left-0" : "hidden"}>
            {t('headings.no_internet_popup_label')}
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default Screensaver;
